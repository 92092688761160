.panelWrapper {
    display: flex;
}

.panelWrapper:hover {
    opacity: 0.7;
    cursor: pointer;
}

.panelWrapper:not(:last-child) {
    margin-bottom: 40px;
}

.icon {
   margin-right: 14px;
   margin-top: auto;
   margin-bottom: auto;
   padding-top: 3px;
}

.option {
    font-size: 24px;
    margin-top: auto;
    margin-bottom: auto;
    text-align: left;
}

a {
    color: white;
    font-weight: 600;
    text-decoration: none;
}