@import url('https://fonts.googleapis.com/css2?family=Anek+Latin&display=swap');

* {
  font-family: 'Anek Latin', sans-serif !important;
}

body {
  background: #fff;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
