.navWrapper {
    width: 100%;
    height: 70px;
    background: white;
    position: fixed;
    display: flex;
    top: 0px;
    justify-content: space-between;
    z-index: 2;
    border-bottom: 1px solid;
}

.navTitle {
    font-size: 24px;
    display: none;
}

.navLogo {
    padding-right: 10px;
}

.navMenuIcon {
    padding: 0px 15px;
}

@media (min-width: 365px) {
    .navTitle {
        display: flex;
    }
 }
