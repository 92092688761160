.priceItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 9px;
}

.leftItem, .rightItem {
    margin-right: 20px;
    font-size: 21px;
}

.leftItem {
    text-align: left;
}

.rightItem {
    text-align: right;
}

#price, #time {
    color: black;
}

.definition {
    font-size: 16px;
    text-align: left;
    line-height: 1.4;
    letter-spacing: 0.2px;
}

.duration {
    line-height: 1.4;
    letter-spacing: 0.2px;
    font-size: 18px;
}