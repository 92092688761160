.shortWrapper {
    height: 180px;
    width: 100%;
    left: 0;
    display: flex;
    margin-top: 70px;
    background-repeat: no-repeat;
    background-size: cover;
}

.shortInnerWrapper {
    margin: auto;
    /* margin-top: 156px; */
    padding: 0px 15px;
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
}

.shortInnerTitle {
    color: #fff;
    font-size: 44px;
    padding-right: 8px;
    padding-left: 8px;
    font-weight: bolder;
}