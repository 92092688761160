/* Float four columns side by side */
.cardColumn {
    float: left;
    width: 33.3%;
    padding: 0 10px;
  }

  /* Remove extra left and right margins, due to padding */
  .cardRow {margin: 0 -5px;}

  /* Clear floats after the columns */
  .cardRow:after {
    content: "";
    display: table;
    clear: both;
  }

  /* Responsive columns */
  @media screen and (max-width: 700px) {
    .cardColumn {
      width: 100%;
      display: block;
    }
    .cardColumn:not(:last-child) {
      margin-bottom: 20px;
     }
  }

  @media screen and (max-width: 700px) {
    .contactText, .cardContact, a {
      font-size: 18px !important;
    }
  }

  @media screen and (max-width: 700px) {
    .cardContact {
      padding: 16px;
    }
  }

  /* Style the counter cards */
  .cardContact {
    padding: 6px;
    text-align: center;
  }

  .contactText, .contactText a {
    color: rgb(102, 102, 102);
    font-size: 18px;
  }

  .contactText {
    font-size: 18px !important;
  }

  .contactText a:hover {
    color: #47c0bc;
  }