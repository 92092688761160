* {
  box-sizing: border-box;
}
@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.outerWrapper {
  margin: 0 -5px;
}

.outerWrapper:after {
  content: "";
  display: table;
  clear: both;
}

.inWrapper {
  float: left;
  padding: 0 15px;
}

#aboutMeCard {
  width: 70%;
}

#profilePic {
  width: 30%;
}

#profilePic img {
  width: 100%;
  animation: fadeIn 2s;
  -webkit-animation: fadeIn 2s;
  max-height: 300px;
  max-width: 260px;
}


@media screen and (max-width: 900px) {
  .inWrapper {
    display: block;
    margin-bottom: 20px;
    padding: 0px !important;
  }
  #profilePic, #aboutMeCard {
    width: 100%;
  }
}

@media (min-width:320px)  {
  #profilePic img {
    width: 100%;
  }
}

@media (min-width:480px)  {
  #profilePic img {
    width: 100%;
  }
 }
@media (min-width:600px)  {
  #profilePic img {
    width: 70%;
  }
 }
@media (min-width:801px)  {
  #profilePic img {
    width: 70%;
  }
}
@media (min-width:901px)  {
  #profilePic img {
    width: 100%;
  }
}
@media (min-width:1025px) {
  #profilePic img {
    width: 100%;
  }
}
@media (min-width:1281px) {
  #profilePic img {
    width: 100%;
  }
 }

#aboutMeCard {
    font-size: 20px;
    text-align: left;
    line-height: 1.4;
    letter-spacing: 0.2px;
  }