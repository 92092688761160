#aboutCard {
    background-color: #f2f2f2;
    font-size: 20px;
    color: black;
    /* display: flex;
    justify-content: center; */
}

#aboutCardText {
    margin-top: 6px;
}

.row {
    margin: 15px -5px;
}

@media screen and (max-width: 800px) {
    .row {
      margin: 0px -5px;
    }
  }